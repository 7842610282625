import React, { createContext, useRef } from 'react'
import { useAgentDetails } from '../hooks/use-agent-details'
import { useChatMessages } from '../hooks/use-chat-messages'
import { getChatsList, getArchives, ChatSDK } from '../utils/chat-sdk'
import { getBotStore, get36Store, getMsgNumStore, getBotMsgNumStore } from "../utils/store";

export const ChatContext = createContext({})

const ChatProvider = ({ children }) => {

  const { agentDetails } = useAgentDetails();
  const {
    fetchMessages,
    messages,
    sendMessage,
    chatList,
    setChatList,
    activeChat,
    openTrans,
    setOpenTrans, 
    setSysTags,
    sysTags
  } = useChatMessages();

  const getLoginName = () => {
    return ChatSDK._agentDetails.my_profile?.name;
  }
  const platid = process.env.REACT_APP_PLAT_ID;

  const getSysTags = () => {
    try {
      const requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ platid: platid })
      };
      fetch("/vn_getsystags", requestOptions2)
        .then(res => res.json())
        .then(data => {
          if (data) {
            if (data && data.result && data.result.length > 0) {
              const results=data.result
              const sortReuslts=[...results].sort((a,b)=>{
                return a.tag.localeCompare(b.tag)
              })
              setSysTags(sortReuslts)
            }
          }

        })
    } catch (error) {

    }

  }

  const getTags = (psids, activeChats) => {
    try {
      const requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ psids: psids,platid:platid })
      };
      fetch("/vn_369_gettags", requestOptions2)
        .then(res => res.json())
        .then(data => {
          if (data) {
            if (data && data.result && data.result.length > 0) {
              data.result?.forEach(item => {
                var chatToChange = activeChats?.find(r => r.psid === item["psid"]);
                if (chatToChange) {
                  chatToChange.tags = JSON.parse(item["usertags"]) ;
                }
              })
            }
          }

        })
    } catch (error) {

    }

  }
  const getActiveChats = async () => {
    try {

      const loginName = getLoginName();

      const { chats_summary } = await getChatsList()
      // console.log("agentDetails222222-----------",loginName,chats_summary);
      const activeChats = chats_summary.filter(chat => chat?.last_thread_summary?.active && chat?.users.find(({ type, present, name }) => type === 'agent' && present == true && name === loginName) != null)
      console.log("activeChats-----------", activeChats);
      var psids = [];
      activeChats?.forEach(chatItem => {
        if (getBotStore(chatItem.id) && getBotStore(chatItem.id) == "1") {
          chatItem.noBot = true;
        }
        try {
          const st36 = get36Store(chatItem.id);
          if (st36 && st36 != "") {
            const st36num = parseInt(st36);
            if (st36num >= 5) {
              chatItem.needInit = false;
              if (st36num >= 30) {
                chatItem.pin = true;
              }
              chatItem.userType = st36num;
            } else {
              chatItem.needInit = true;
            }
          } else {
            // chatItem.upChat = true;
          }

          const client_id = chatItem?.properties.source?.client_id;
          const conversation_link = client_id ? chatItem?.properties[client_id]?.conversation_link : null;
          const isfb = conversation_link ? (conversation_link.indexOf("facebook.com") >= 0 ? true : false) : false;
          var psid = ""
          if (isfb) {
            psid = client_id ? chatItem?.properties[client_id]?.id : null;
          }else{
            const customer = chatItem?.users.find(({ type }) => type === 'customer')
            psid=customer?.id;
          }
          psids.push(psid)
          chatItem.psid = psid;

        } catch (error) {

        }

        const msgNum = getMsgNumStore(chatItem.id);
        if (msgNum) chatItem.msgNum = parseInt(msgNum);
        const botMsgNum = getBotMsgNumStore(chatItem.id);
        if (botMsgNum) chatItem.botMsgNum = parseInt(botMsgNum);
        chatItem.lastDate = chatItem.last_event_per_type?.message?.event?.created_at || chatItem.last_thread_summary.created_at
        chatItem.lastText = chatItem.last_event_per_type?.message?.event?.text || ''

      })

      if (psids && psids.length > 0) {
        getTags(psids, activeChats)
      }

      if (activeChats) {
        setChatList(activeChats?.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate)));
      }



      if (activeChats?.length) {
        await fetchMessages(activeChats[0]);
      }

      getSysTags()
    } catch (error) {
      console.error(`Unable to fetch agent chats: `, error)
    }
  }

  const getArchivedChats = async () => {
    try {

      const loginName = getLoginName();
      const { chats } = await getArchives()


      const uChats = chats.filter(chat => chat?.users.find(({ type, present, name }) => type === 'agent' && present == true && name === loginName) != null)

      setChatList(uChats);

      if (uChats?.length) {
        await fetchMessages(uChats[0]);
      }
    } catch (error) {
      console.error(`Unable to fetch archived chats: `, error)
    }
  }

  return (
    <ChatContext.Provider value={{
      chatList,
      setChatList,
      messages,
      sendMessage,
      activeChat,
      fetchMessages,
      agentDetails,
      getActiveChats,
      getArchivedChats,
      openTrans,
      setOpenTrans,
      sysTags,
      getSysTags
    }}>

      {children}
    </ChatContext.Provider>
  )
}

export default ChatProvider
