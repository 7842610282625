import React from "react";
import styled from "@emotion/styled";
import { AiFillFileText } from "react-icons/ai";
import { IconContext } from "react-icons";
const MessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ isCustomer }) =>
    isCustomer ? "flex-start" : "flex-end"};
`;

const MessageAuthor = styled.small`
  position: absolute;
  top: -3px;
  > span{
    display:${({ isCustomer }) =>
    isCustomer ? "none !important" : "block"};
  }
`;

const getBackground = (isCustomer, isFromBot, theme) => {
  if (isFromBot) {
    return '#deab8a';
  }
  return isCustomer ? theme.secondary : theme.primary
}

const MessageText = styled.p`
  padding: 1rem;
  background: ${({ isCustomer, isFromBot, theme }) => getBackground(isCustomer, isFromBot, theme)};
  color: ${({ isCustomer }) => (isCustomer ? "black" : "white")};
  border-radius: 10px;
`;

const MessageChineseText = styled.p`
  background: #cdcdcd;
  color: black;
  border-radius: 10px;
  font-size: 10px;
`;

const FileCont = styled.div`
display: flex;
-webkit-box-align: center;
align-items: center;
`;
const IconDiv = styled.div`
display: inline-flex;
-webkit-box-align: center;
align-items: center;
width: 28px;
height: 28px;
line-height: 28px;
`;
const FileDiv = styled.div`
padding-left: 8px;

-webkit-box-flex: 1;
flex-grow: 1;
display: flex;
flex-wrap: wrap;
`;
const FileA = styled.a`
pointer-events: auto;
cursor: pointer;
color: var(--action-primary-default);
text-decoration: none;
line-height: 1.27;
display: inline-flex;
word-break: break-all;
color:#fff;
`;

const FileSpan = styled.span`
font-size: 13px;
width: 100%;
line-height: 1.46;
display: inline-flex;
color:#fff;
`;
const Message = ({ message, user }) => {
  const userName = user?.name || "";
  const isCustomer = user?.type === "customer";
  const isPic = e => {
    var items = e.split('.');
    var res = false;
    if (items) {
      var t = items[items.length - 1];
      res = (t == 'jpg' || t == 'png' || t == 'gif');
    }
    return res;
  }
  return (
    <>
      <MessageWrapper isCustomer={isCustomer}>
        <MessageAuthor isCustomer={isCustomer} isFromBot={message.isFromBot}>
          {/* {userName} */}
          <span class="icon-msg-robot" style={{ 'display': message.isFromBot ? 'block' : 'none' }}></span>
          <span class="icon-msg-rengong" style={{ 'display': !message.isFromBot ? 'block' : 'none' }}></span>
        </MessageAuthor>


        {isPic(message.name) && <p style={{ padding: "10px 20px 12px" }}> <img width="266" height="auto" style={{ cursor: "zoom-in", textAlign: "left",borderRadius: '10px' }} onClick={e => window.open(message.url, '_blank')} target="_blank" src={message.thumbnail2x_url} /></p>}
        {!isPic(message.name) && <MessageText isCustomer={isCustomer} isFromBot={message.isFromBot}>
          <FileCont>
            <IconDiv> <IconContext.Provider value={{ color: "#16C59A", className: "IconText", size: '3em' }}>
              <AiFillFileText />
            </IconContext.Provider></IconDiv>
            <FileDiv>
              <FileA target="_blank" href={message.url}>{message.name}</FileA><FileSpan>{message.size / 1000}kb</FileSpan>
            </FileDiv>
          </FileCont>
        </MessageText>}

      </MessageWrapper>

    </>
  );
};

export default Message;
