import { useEffect, useState, useRef } from "react";
import AccountsSDK from '@livechat/accounts-sdk'

const ACCESS_TOKEN_KEY = 'access_token'

export const useAuth = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const instance = useRef(null);

  const signInWithPopup = async () => {
    try {
      setIsLoading(true)
      const data = await instance.current.popup().authorize()
     
      if (data?.access_token) {
        const { access_token, ...details } = data

        setAccountDetails(details)
        setAccessToken(access_token)
        sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token)
      } else {
        setAccessToken(null);
      }
    } catch (error) {
      console.error('Authorization error: ', error)
      
      setAccessToken(null);
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    instance.current = new AccountsSDK({
      // Test 66b7305e7151657a694fc80394bdab41 
      // BR 814552ce53c68742c3989f3b61bd760d  nginx端口20000
      // VN 52b8051e4af26aa799d8c3a1ab31f1c3  nginx端口20020
      // 369 7dd9b143440e4a4f0276e2ea8533d356 nginx端口20030
      //test 54cf3a59a73bfcaa423add953391298b
      client_id:  process.env.REACT_APP_CLIENT_ID,
    })

    console.log(9999994, process.env.REACT_APP_CLIENT_ID);
    console.log(9999994, process.env.REACT_APP_PLAT_ID);
    
    const checkIfLoggedIn = () => {
      const sessionToken = sessionStorage.getItem(ACCESS_TOKEN_KEY)
      setAccessToken(sessionToken)
      setIsLoading(false)
    }

    checkIfLoggedIn()
  }, [])

  return {
    accessToken,
    accountDetails,
    isLoggedIn: accessToken && !isLoading && !error,
    instance: instance.current || null,
    isLoading,
    error,
    signInWithPopup,
  };
}
