import React from "react";
import { Message, SystemMessage, FilledForm, File } from '../event'


const ChatMessage = ({ users, message,chatid }) => {
  const getChatUser = authorId => users.find(({ id }) => id === authorId) || { type: "customer" };
 
  switch (message?.type) {
    case "message":
      const user = getChatUser(message.author_id);

      return (
        <Message key={message.id} message={message} chatid={chatid} user={user} />
      );
    case "file":
      const user2 = getChatUser(message.author_id);

      return (
        <File key={message.id} message={message} user={user2} />
      );

    case "system_message":
      return <SystemMessage key={message.id} message={message} />;

    case "filled_form":
      return <FilledForm key={message.id} message={message} />;

    default:
      return null;
  }
}

export default ChatMessage;
