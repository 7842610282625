import React, { useRef, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import ChatForm from "./Form";
import Message from "./Message";
import Instruction from "./Instruction";
import { ChatContext } from '../../context/chat';
import { Switch } from "@livechat/design-system";
import Select from 'react-select';

import { transKey, notifyKey, transStatusKey, getStoreItem, setStoreItem ,setMsgNumStore,setBotMsgNumStore} from "../../utils/store";

const SwitchBox = styled.div`
display:flex;
justify-content: space-between;
border-bottom:2px solid #e2e8eb;
padding:26px 0 26px;
align-items: center;
font-size:14px;
color:#112349;
margin: 0 28px 0 25px;
`;

const SwitchBoxLeft = styled.div`
display:flex;
align-items: center;
padding-left:42px;

`;

const SwitchBoxRight = styled.div`
width:40%;
display:flex;
`;

const SwitchBoxTitle = styled.span`
  padding:0 15px 0 0 ;
  font-size:12px;
`;


const Wrapper = styled.div`
  height: 100%;
  // width: calc(100% - 380px);
  flex-grow:1;
  padding: 0 0px 0 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  overflow-x:hidden;
  padding:20px 0 0 0;
`;

const ChatMessages = ({ onlyMessages = false }) => {
  const ref = useRef();
  const options = [
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Chinese', label: '中文' },
    { value: 'Vietnamese', label: 'Vietnamese' }
  ];

  const { messages, activeChat, chatList ,setChatList} = useContext(ChatContext)
  const chatUsers = activeChat?.users || [];
  const customer = chatUsers.find(({ type }) => type === 'customer');
  const [transValue, setTransValue] = useState(getStoreItem(transKey));
  const [notifyStatus, setNotifyStatus] = useState(getStoreItem(notifyKey));
  const [transStatus, setTransStatus] = useState(getStoreItem(transStatusKey));

  const handleTranChange = (item) => {
    setTransValue(item.value)
    setStoreItem(transKey, item.value);

  };
  const handleNotifyStatusChange = (item) => {
    const val = item.target.checked ? "1" : "0";
    setNotifyStatus(val)
    setStoreItem(notifyKey, val);

  };
  const handleTransStatusChange = (item) => {
    const val = item.target.checked ? "1" : "0";
    setTransStatus(val)
    setStoreItem(transStatusKey, val);

  };
  const clearNum=()=>{
   
    const chatToChange = chatList?.find(item => item.id === activeChat?.id);
    if(chatToChange){
      setMsgNumStore(chatToChange.id, 0)
      setBotMsgNumStore(chatToChange.id, 0)
      chatToChange.msgNum = 0;
      chatToChange.botMsgNum = 0;
      chatToChange.needAdmin = false;
    }

    const newChats = [...(chatList || [])];
     setChatList(newChats);
 }
  useEffect(() => {
    if (ref.current) {
      // Scroll to the bottom of the chat with every new message
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  }, [messages]);

  if (!chatList?.length) {
    return (
      <Wrapper>
        <Instruction />
      </Wrapper>
    )
  }

  return (
    <Wrapper onClick={clearNum}>
      <SwitchBox>
        <SwitchBoxLeft>
          <div>Name: {customer?.name}</div>
        </SwitchBoxLeft>
        <SwitchBoxLeft>
          {customer?.email}
        </SwitchBoxLeft>
        <SwitchBoxRight>
          {customer?.last_visit?.ip + "(" + customer?.last_visit?.geolocation?.country + ")"}
        </SwitchBoxRight>

      </SwitchBox>
      <MessageWrapper ref={ref} onlyMessages={onlyMessages}>
        {messages ? messages?.map(message => <Message key={message?.id} chatid={activeChat?.id}  message={message} users={chatUsers} />) : ""}
      </MessageWrapper>

      {!onlyMessages && <ChatForm chatId={activeChat?.id} />}
    </Wrapper>
  );
};

export default ChatMessages;
