import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Form, InputField, Input, Button, CheckboxField, SearchBar } from "@livechat/design-system";
import { ChatContext } from '../../context/chat';
import EmojiPicker from 'emoji-picker-react';
import useFileUpload from 'react-use-file-upload';
import { ChatSDK, getChat } from "../../utils/chat-sdk";
import { SketchPicker } from 'react-color';
import { AiOutlineSmile, AiOutlineFileAdd, AiOutlineSend, AiOutlineClear, AiOutlineArrowRight, AiOutlineArrowLeft, AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { transKey, notifyKey, transStatusKey, getStoreItem, set36Store } from "../../utils/store";
import { setBotStore } from "../../utils/store";
var replyJson369 = require('../../json/reply.json');
var replyJson66 = require('../../json/reply66.json');
const SendCont = styled.div`
padding: 16px 0px 16px 16px;
background:#fff;
display: flex;
min-width: 0px;
-webkit-box-align: center;
align-items: center;
text-align: left;
position: relative;
border-radius: 8px;
height:97px;
box-sizing: border-box;
margin:0 26px;
`;
const SendText = styled.textarea`
appearance: none;
resize: none;
height: 1.5em;
line-height: 1.5em;
min-width: 0px;
width: 100%;
font-size: 1em;
-webkit-box-flex: 1;
flex-grow: 1;
max-width: 100%;
font-family: inherit;
padding: 1.25em 13.75em 1.25em 1em;
overflow-y: auto;
margin-top: 0px;
transition: box-shadow 0.1s ease-in-out 0s;
color: rgb(17, 17, 17);
background-color: #fff;
text-align: left;
border:0;
border-left:2px solid #ececec;
`;
const SendBtns = styled.div`
position: absolute;
top: 0px;
height: 100%;
display: flex;
-webkit-box-align: center;
align-items: center;
right: 1em;
color: rgb(17, 17, 17);
text-align: left;
`;
const EmojiBtn = styled.label`
cursor: pointer;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-flex: 0;
flex-grow: 0;
width:55px;
`;
const FileLable = styled.label`
cursor: pointer;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-flex: 0;
flex-grow: 0;
width: 55px;
`;
const SendBtn = styled.label`
position:relative;
margin: 0px 0px 0px 2px;
cursor: pointer;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-flex: 0;
flex-grow: 0;
width: 59px;
height: 59px;
border-radius: 59px;
`;
const EmojiDiv = styled.div`
width: 312px;
min-height: 100px;
bottom: calc(100% - 0px);
left: 10px;
position: absolute;
z-index: 2000;
border: 0px solid var(--border-basic-primary);
border-radius: 4px;
box-shadow: var(--shadow-pop-over);
background-color: var(--surface-primary-default);
`;
const ReplyDiv = styled.div`
width: 312px;
min-height: 100px;
bottom: calc(100% - 0px);
right: 0;
position: absolute;
z-index: 2000;
border: 0px solid var(--border-basic-primary);
border-radius: 12px;
background-color: #fff;
box-shadow: 0 0 10px rgba(0,0,0,.2);
overflow:hidden;
`;



const UploadDiv = styled.div`
width: 312px;
min-height: 100px;
bottom: calc(100% - 10px);
left: 10px;
position: absolute;
z-index: 2000;
border: 0px solid var(--border-basic-primary);
border-radius: 4px;
box-shadow: var(--shadow-pop-over);
background-color: var(--surface-primary-default);
`;

const ReplyLiBox = styled.div`
> p{cursor:pointer; display:flex;padding: 15px 20px;
  justify-content: space-between; border-bottom:1px solid #f2f2f2;align-items: center;}
> p:hover{background:#f2f2f2; }
`;
const ReplyLiCon = styled.div`

`;
const ListSearch = styled.div`
  padding:3px 3px 12px 3px;
  .lc-search-bar__container{
    background:#f6f6f6;
    border-radius:8px;
    font-size:14px; 
    color:#b0bac4;
    height:38px;
  }
  .lc-search-bar__input{ border:0 !important; background:#f6f6f6 !important;border-radius:8px;}
`;

const ChatForm = () => {
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const inputRef = useRef();

  const jsons = [
    { cid: "52b8051e4af26aa799d8c3a1ab31f1c3", jsons: [] },
    { cid: "814552ce53c68742c3989f3b61bd760d", jsons: [] },
    { cid: "454eab6624be0df1158b4eb3122c4dca", jsons: [] },
    { cid: "7dd9b143440e4a4f0276e2ea8533d356", jsons: [] }
  
  ]

  const jsonitem = jsons.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);

  const { sendMessage, activeChat, chatList, setChatList, openTrans, sysTags, getSysTags } = useContext(ChatContext)

  const [showEmoji, setShowEmoji] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [showReplyCon, setShowReplyCon] = useState(false);
  const [replyCon, setReplyCon] = useState("");
  const [replyJson, setreplyJson] = useState(jsonitem ? jsonitem.jsons : []);

  const [searchValue, setSearchValue] = useState("");
  const [transStatusValue, setTransStatusValue] = useState(getStoreItem(transStatusKey) && getStoreItem(transStatusKey) == "1");
  const [finishTrans, setFinishTrans] = useState(true);
  const [btnStatus, setBtnStatus] = useState(true);

  const [copyFiles, setCopyFiles] = useState([]);

  const [activeTags, setActiveTags] = useState(activeChat?.tags || []);

  const [showAddTag, setShowAddTag] = useState(false);
  const [showAddReply, setShowAddReply] = useState(false);

  const [inputTag, setInputTag] = useState("");
  const [inputColor, setInputColor] = useState("#d91414");

  const [inputRepTitle, setInputRepTitle] = useState("");
  const [inputRepCont, setInputRepCont] = useState("");

  const inputRepTitleChange = ({ target }) => {
    setInputRepTitle(target.value);
  }

  const inputRepContChange = ({ target }) => {
    setInputRepCont(target.value);
  }

  const inputTagChange = ({ target }) => {
    setInputTag(target.value);
  }
  const inputColorChange = ({ hex }) => {
    console.log(hex)
    setInputColor(hex);
    // setInputColor(target.value);
  }

  const SearchBarChange = (value) => {
    setSearchValue(value);
  }
  const handleInputChange = ({ target }) => {
    setInputValue(target.value)
    if (target.value == '') {
      setFinishTrans(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      sendChatMessage(e);
    }
  }

  const sendChatMessage = e => {
    if (!inputValue || !btnStatus) {
      return;
    }
    e.preventDefault();

    setBtnStatus(false);
    const transStatus = getStoreItem(transStatusKey);
    //!finishTrans &&
    if ( transStatus && transStatus == "1") {
      const trans = getStoreItem(transKey);
      const requrl = trans ? "/Translate_to_" + trans : "/Translate_to_Vietnamese";

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ text: inputValue })
      };

      //fetch('/Translate_to_Arabic', requestOptions)
      //fetch('/Translate_to_Vietnamese', requestOptions)
      fetch(requrl, requestOptions)
        .then(res => res.json())
        .then(data => {
          /*接到request data後要做的事情*/
          //this.setState({repoName: data[0]['name']});
          //  console.log('fetch data: ', inputValue, data)
          setBtnStatus(true);
          let reply = data?.translated_text;
          if (reply?.[0] === '"') {
            reply = reply.substr(1);
          }
          if (reply?.length && reply?.[reply?.length - 1] === '"') {
            reply = reply.substr(0, reply.length - 1);
          }
          setFinishTrans(true);
          //setInputValue(reply);
          sendMessage(activeChat?.id, reply);
          setInputValue("");
        })
    } else {
      //setFinishTrans(false);
      sendMessage(activeChat?.id, inputValue);
      setInputValue("");
      setBtnStatus(true);
    }


  };

  const uploadFile = (chatId, formData, isend) => {

    fetch('https://api.livechatinc.com/v3.5/agent/action/upload_file', {
      method: 'POST',
      body: formData,
      headers: {

        "Authorization": ChatSDK._getToken()
      }
    }).then(res => res.json())
      .then(data => {
        if (data && data.url) {
          sendfile(chatId, data.url);
          if (isend) {
            clearImg();
          }

        }

      })
      .catch(error => console.log(error));
  }

  const sendfile = (chatId, url) => {


    fetch('https://api.livechatinc.com/v3.5/agent/action/send_event', {
      method: 'POST',
      body: JSON.stringify({
        "chat_id": chatId,
        "event": {
          "type": "file",
          "url": url,
          "visibility": "all"
        }
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": ChatSDK._getToken()
      }
    }).then(res => res.json())
      .then(data => {


      })
      .catch(error => console.log(error));
  }

  const upload = e => {
    e.preventDefault();
    const formData = createFormData();
    var indexid = 1;

    const newfiles = [...files, ...copyFiles]
    var lens = newfiles.length;
    for (const file of newfiles) {
      const postdata = new FormData();
      postdata.append('file', file);
      uploadFile(activeChat?.id, postdata, indexid == lens);
      indexid++;
    }

  }
  const clearImg = e => {

    clearAllFiles();
    setCopyFiles([]);
  }
  const onEmojiClick = e => {
    sendMessage(activeChat?.id, e.emoji);
    setShowEmoji(false)
  }

  /*robot*/
  const getThreadId = (chat) => {
    if (chat?.last_thread_summary) {
      return `${chat.last_thread_summary.id}`
    }

    return `${chat?.thread.id}`
  }
  const markAsNoBot = (chat) => {


    const chatToChange = chatList?.find(item => item.id === chat.id);
    if (chatToChange) {
      setBotStore(chatToChange.id, !chatToChange.noBot ? "1" : "0")
      chatToChange.noBot = !chatToChange.noBot;
      chatToChange.needAdmin = false;
      activeChat.noBot = chatToChange.noBot;
    }
    const newChats = [...(chatList || [])];
    setChatList(newChats);
  }

  const pin = (chat) => {


    const chatToChange = chatList?.find(item => item.id === chat.id);
    if (chatToChange) {
      if (chatToChange.pin) {
        set36Store(chat.id, "5")
      } else {
        chatToChange.userType = "90";
        set36Store(chat.id, "90")
      }
      chatToChange.pin = !chatToChange.pin;

    }
    const newChats = [...(chatList || [])];
    setChatList(newChats);
  }

  const handlePastePic = (e) => {
    try {
      //e.preventDefault();
      let file = null;
      const items = (e.clipboardData || window.clipboardData).items;
      if (items && items.length) {
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
      }

      if (file) {
        setCopyFiles([...copyFiles, file])

        // 此时获取到file文件对象，即可处理上传相关处理
      }
    } catch (error) {

    }

  }

  const resetTag = (chat, tags) => {
    const chatToChange = chatList?.find(item => item.id === chat.id);
    if (chatToChange) {
      chatToChange.tags = tags;
    }
    const newChats = [...(chatList || [])];
    setChatList(newChats);
  }

  const vn_369_edittags = (tags) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ chatid: activeChat?.id, psid: activeChat?.psid,platid:platid, tags: JSON.stringify(tags) })
    };
    fetch("/vn_369_edittags", requestOptions)
      .then(res => res.json())
      .then(data => {
        setActiveTags(tags)
        resetTag(activeChat, tags)
      })
  };

  const delSysTag = (e,id) => {

    e.preventDefault();
    if (window.confirm('You sure you want to delete it?')) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ id: id })
      };
      fetch("/vn_deletesystag", requestOptions)
        .then(res => res.json())
        .then(data => {
        
          getSysTags();
        })
    }

  };


  const editTag = (itemTag) => {
    console.log("itemTag-------", itemTag)
    var newTags = [...activeTags];
    var item = activeTags.find(r => r == itemTag);
    if (item) {
      newTags = activeTags.filter(r => r != itemTag);
    } else {
      newTags = [...activeTags, itemTag]
    }
    vn_369_edittags(newTags)
  }

  const platid = process.env.REACT_APP_PLAT_ID;
  const addSysTag = () => {
    if (!inputTag || inputTag == "") {
      alert('Enter label name')
      return;
    }
    if (!inputColor || inputColor == "") {
      alert('Please select a color')
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ tag: inputTag, color: inputColor, ordernum: 0, platid: platid,type:0 })
    };
    fetch("/vn_editsystag", requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.result) {
          //alert('Added successfully')
          setInputTag("");
          setShowAddTag(false);
          getSysTags();
        }
      })
  };

  const addSysReply = () => {
    if (!inputRepTitle || inputRepTitle == "") {
      alert('Enter key')
      return;
    }
    if (!inputRepCont || inputRepCont == "") {
      alert('Enter value')
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ tag: inputRepTitle, color: inputRepCont, ordernum: 0, platid: platid,type:1 })
    };
    fetch("/vn_editsystag", requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.result) {
          //alert('Added successfully')
          setInputRepTitle("");
          setInputRepCont("")
          setShowAddReply(false);
          getSysTags();
        }
      })
  };

  const chatId = getThreadId(activeChat)
  const isActive = chatId === getThreadId(activeChat)
  const buttonType = isActive ? 'primary' : 'secondary'
  useEffect(() => {
    setInputValue("");
    clearImg();
    setActiveTags(activeChat?.tags || []);
  }, [activeChat?.id])

  useEffect(() => {
    setActiveTags(activeChat?.tags || []);
  }, [activeChat?.tags])

  return (
    <Form onSubmit={sendChatMessage} style={{ width: "100%" }}>

      <InputField
        ref={inputRef}
        type="file"
        multiple
        onChange={(e) => {
          clearAllFiles();
          setFiles(e, 'a');
          inputRef.current.value = null;
        }}
        style={{ display: 'none', padding: '0', margin: '0' }}
      />
      {(files.length > 0 || copyFiles.length > 0) &&
        <div className="file-li-box">
          <ul>{fileNames?.map((name) => (
            <li style={{ listStyleType: "none" }} key={name}><span>{name}</span>     </li>))}
            {copyFiles?.map((item) => (
              <li style={{ listStyleType: "none" }} key={item.name}><span>{item.name}</span>     </li>))}
          </ul>
          <div className="file-btn">
            <Button kind='primary' onClick={upload} loaderLabel="submit">submit</Button>
            <span onClick={clearImg} >
              <IconContext.Provider value={{ size: "24px", className: "global-class-name" }}>
                <AiOutlineClear />
              </IconContext.Provider>
            </span>
          </div>
        </div>
      }
      <div
        // outline={true}
        key={'jq-key' + chatId}

        kind={buttonType}
        title={!activeChat?.noBot ? 'cancel robot answer' : 'robot answer'}
      >
        {activeChat?.noBot && <span style={{ display: "block" }} className="rengong-ts" >Currently in manual service mode, please reply seriously and positively</span>}  </div>

      <div className="tips-box">
        {sysTags?.filter(r=>  r.type==0).map((itemTag) => {
          return (
            <span  className={activeTags.indexOf(itemTag.tag)>=0 ? 'tips-current ' : ' '}   style={{ 'background': itemTag.color }} > 
              <i  onClick={() => editTag(itemTag.tag)}>{itemTag.tag}</i>
              <em className="tips-del" onClick={(e)=>{delSysTag(e,itemTag.id)}}><AiFillCloseCircle /></em>
            </span>
          )
        })}
        <div className="tips-add" onClick={(e) => { setShowAddTag(!showAddTag) }}>+</div>
      </div>
      <SendCont>
        {/* <InputField
      
        onChange={handleInputChange}
        style={{ width: "100%", marginTop: "auto" }}
      /> */}

        <div className="send-robot"
          //  outline={true}
          key={'jq' + chatId}
          onClick={() => markAsNoBot(activeChat)}
          kind={buttonType}
          title={!activeChat?.noBot ? 'cancel robot answer' : 'robot answer'}
        >
          {/* <span className="rengong-ts" style={{ display: chatItem.noBot ? 'block' : 'none' }}>当前为人工服务模式，请认真积极回复</span> */}
          <span className={!activeChat?.noBot ? 'icon-robot robot-on' : 'icon-robot robot-off'}></span><p>F9</p></div>

        <div className="send-robot"
          //  outline={true}
          key={'pin' + chatId}
          onClick={() => pin(activeChat)}
          kind={buttonType}
          title={!activeChat?.pin ? 'cancel robot answer' : 'robot answer'}
        >
          {/* <span className="rengong-ts" style={{ display: chatItem.noBot ? 'block' : 'none' }}>当前为人工服务模式，请认真积极回复</span> */}
          <span className={!activeChat?.pin ? 'icon-robot top-on' : 'icon-robot top-off'}></span><p>pin</p></div>

        <SendText className="chat-message" onPaste={handlePastePic} onKeyDown={onEnterPress} onChange={handleInputChange} disabled={!activeChat}
          value={inputValue}
          id="chat-message"
          placeholder="Write message...">

        </SendText>
        <SendBtns>
          <EmojiBtn onClick={(e) => { setShowEmoji(!showEmoji) }}>
            {/* <AiOutlineSmile /> */}
            <span className="icon-smile"></span>
          </EmojiBtn>
          <FileLable>
            {/* <AiOutlineFileAdd onClick={() => inputRef.current.click()} /> */}
            <span className="icon-file" onClick={() => inputRef.current.click()}></span>
          </FileLable>
          <FileLable>
            {/* <AiOutlineFileAdd onClick={() => inputRef.current.click()} /> */}
            <span className="icon-reply" onClick={(e) => { setSearchValue(''); setReplyCon(''); setShowReplyCon(false); setShowReply(!showReply); }}></span>
          </FileLable>


          {openTrans && !finishTrans ? <SendBtn className={inputValue ? 'send-btn send-active' : 'send-btn'} style={{
            cursor: inputValue && btnStatus ? 'pointer' : 'not-allowed ', backgroundColor: inputValue ? '#3b99d9' : 'transparent', color: inputValue && btnStatus ? '#fff' : 'rgb(17, 17, 17)', 'display': 'flex',
            'flex-direction': 'column'
          }}  >

            {/* <AiOutlineSend onClick={sendChatMessage} /> */}
            {/* <span className="icon-send"></span> */}


            <span onClick={sendChatMessage} className={inputValue && btnStatus ? 'icon-send' : 'icon-send-gray'} ></span>
            <p style={{ color: 'red', width: '46px', left: '65%' }}>{btnStatus ? "翻译" : "翻译中"} </p>

          </SendBtn> : <SendBtn className={inputValue && btnStatus ? 'send-btn send-active' : 'send-btn'} style={{
            cursor: inputValue && btnStatus ? 'pointer' : 'not-allowed ', backgroundColor: inputValue && btnStatus ? '#3b99d9' : 'transparent', color: inputValue && btnStatus ? '#fff' : 'rgb(17, 17, 17)', 'display': 'flex',
            'flex-direction': 'column'
          }}  >

            {/* <AiOutlineSend onClick={sendChatMessage} /> */}
            {/* <span className="icon-send"></span> */}


            <span onClick={sendChatMessage} className={inputValue ? 'icon-send' : 'icon-send-gray'} ></span>
            <p>Enter</p>

          </SendBtn>}
        </SendBtns>
        <EmojiDiv style={{ display: showEmoji ? '' : 'none' }}><EmojiPicker onEmojiClick={onEmojiClick} /></EmojiDiv>
        <ReplyDiv style={{ display: showReply ? '' : 'none' }}>
          <ReplyLiBox>
            <ListSearch style={{ display: showReplyCon ? 'none' : 'flex' }}><SearchBar placeholder="search the guest name..." value={searchValue} onChange={SearchBarChange} />    <div className="reply-add" onClick={(e) => { setShowAddReply(!showAddReply) }}>+</div>
            <p className="reply-close" onClick={(e) => { setShowReply(false) }}><AiFillCloseCircle /></p>
            </ListSearch>
        
            <div className="reply-li-box" style={{ display: showReplyCon ? 'none' : 'block' }}>
              {replyJson?.map((replyItem) => {
                return (
                  <p style={{ display: replyItem?.title.toLowerCase().indexOf(searchValue) >= 0 ? 'flex' : 'none' }} ><span style={{width:'100%'}} onClick={() => { setShowReplyCon(true); setReplyCon(replyItem.contents) }}>{replyItem.title}<AiOutlineArrowRight /></span>
                  </p>
                );
              })}

              {sysTags?.filter(r=>r.type==1).map((replyItem) => {
                return (
                  <p style={{ display: replyItem?.tag.toLowerCase().indexOf(searchValue) >= 0 ? 'flex' : 'none' }} ><span style={{width:'100%'}}  onClick={() => { setShowReplyCon(true); setReplyCon(replyItem.color) }}>{replyItem.tag}<AiOutlineArrowRight /></span>
                      <em className="tips-del" onClick={(e)=>{delSysTag(e,replyItem.id)}}><AiFillCloseCircle /></em>
                  </p>
                );
              })}
            </div>
          </ReplyLiBox>

          <ReplyLiCon className="reply-li-con" style={{ display: showReplyCon ? 'block' : 'none' }}>
            <p onClick={() => { setShowReplyCon(false) }} style={{ background: 'none' }}><AiOutlineArrowLeft /></p>
            <p onClick={() => { setInputValue(replyCon); setFinishTrans(true); setShowReply(false); setSearchValue(''); setShowReplyCon(false) }}>{replyCon}</p>
          </ReplyLiCon>
        </ReplyDiv>
        {showAddTag && <div className="add-tips-box" style={{ display: 'block' }}>
          <p className="add-tips-del" onClick={(e) => { setShowAddTag(false) }}><AiFillCloseCircle /></p>
          <p>name：</p>
          <ListSearch style={{ display: 'block' }}>
            <Input value={inputTag} onChange={inputTagChange} className="add-tips-input"
              type="text" />
          </ListSearch>
          <p>color：</p>
          <div style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: inputColor
          }} />
          <SketchPicker color={inputColor} onChange={inputColorChange} />
          {/* <Input value={inputColor} onChange={inputColorChange}  className="add-tips-input"
              type="text"  /> */}
          <Button kind='primary' onClick={(e) => { addSysTag() }} loaderLabel="submit">submit</Button>
        </div>}
        {showAddReply && <div className="add-tips-box" style={{ display: 'block' }}>
          <p className="add-tips-del" onClick={(e) => { setShowAddReply(false) }}><AiFillCloseCircle /></p>
          <p>key:</p>
          <ListSearch style={{ display: 'block' }}>
            <Input value={inputRepTitle} onChange={inputRepTitleChange} className="add-tips-input"
              type="text" />
          </ListSearch>
          <p>value:</p>
          <textarea style={{
            height: '200px',

            fontSize: '15px',
            color: '#424d57',
            width:'100%'
          }}  className="add-tips-input" value={inputRepCont} onChange={inputRepContChange}>

          </textarea>
          {/* <Input 
              type="textarea" /> */}
          <Button kind='primary' onClick={(e) => { addSysReply() }} loaderLabel="submit">submit</Button>
        </div>}
      </SendCont>

    </Form>

  );
};

export default ChatForm;
