
export const transKey = "trans-Key";
export const notifyKey = "notify-Key";
export const transStatusKey = "transStatus-Key";
export const adminTipKey = "admin-tip-";

export const getStoreItem = (key) => {
   var storage = window.localStorage;
   if (!window.localStorage) {
      alert('浏览器不支持localstorage');
      return false;
   } else {
      return storage.getItem(key)
   }
}

export const setStoreItem = (key, val) => {
   var storage = window.localStorage;
   if (!window.localStorage) {
      alert('浏览器不支持localstorage');
      return false;
   } else {
      storage.setItem(key, val)
   }
}

export const getSessionStoreItem = (key) => {

   if (!window.sessionStorage) {
      alert('浏览器不支持localstorage');
      return false;
   } else {
      var storage = window.sessionStorage;
      return storage.getItem(key)
   }
}

export const delSessionStoreItem = (key) => {

   if (!window.sessionStorage) {
      alert('浏览器不支持localstorage');
      return false;
   } else {
      var storage = window.sessionStorage;
      return storage.removeItem(key)
   }
}

export const setSessionStoreItem = (key, val) => {
   if (!window.sessionStorage) {
      alert('浏览器不支持localstorage');
      return false;
   } else {
      var storage = window.sessionStorage;
      storage.setItem(key, val)
   }
}

export const setBotStore = (id, val) => {
   setStoreItem("bot-" + id, val)
}

export const getBotStore = (id) => {
   return getStoreItem("bot-" + id)
}

export const setMsgNumStore = (id, val) => {
   setSessionStoreItem("msgnum-" + id, val)
}

export const getMsgNumStore = (id) => {
   return getSessionStoreItem("msgnum-" + id)
}

export const setBotMsgNumStore = (id, val) => {
   setSessionStoreItem("botmsgnum-" + id, val)
}

export const getBotMsgNumStore = (id) => {
   return getSessionStoreItem("botmsgnum-" + id)
}

export const set36Store = (id, val) => {
   setSessionStoreItem("36-" + id, val)
}

export const get36Store = (id) => {
   return getSessionStoreItem("36-" + id)
}

export const getTransStore = (id) => {
   if (!id) {
      return;
   }
   const jsons = getSessionStoreItem("t_" + id);
   if (jsons) {
      return JSON.parse(jsons);
   } else {
      return null;
   }

}

export const setTransStore = (id, eventid, val) => {
   if (!id || !eventid || !val) {
      return;
   }
   try {
      const key = "t_" + id;
      const stores = getSessionStoreItem(key);
      if (stores) {
         const jsons = JSON.parse(stores);
         if (jsons.length >= 20) {
            jsons.splice(0, 1);
         }
         const obj = { 'id': eventid, 'v': val };
         jsons.push(obj);
         setSessionStoreItem(key, JSON.stringify(jsons));

      } else {
         const jsons = [{ 'id': eventid, 'v': val }];
         setSessionStoreItem(key, JSON.stringify(jsons));
      }
   } catch (error) {
      console.log("setTransStore error", error)
   }
}

export const clearStore =(chataid)=>{
   delSessionStoreItem("t_" + chataid);
   delSessionStoreItem("botmsgnum-" + chataid);
   delSessionStoreItem("msgnum-" + chataid);
   delSessionStoreItem("36-" + chataid);
}

